import { SchemaValidateFunction } from 'ajv';

interface IKeywordFunction extends SchemaValidateFunction {
  errors?: any[];
}
export const uniqueFieldInArrayOfEntitiesKeyword: IKeywordFunction = (
  ...param: Parameters<SchemaValidateFunction>
): boolean => {
  const [schema, data] = param;
  const { fieldName } = schema;

  // skip the validation if there is no field name
  if (!fieldName) {
    return true;
  }
  // skip the validation if it is not Array
  if (!Array.isArray(data)) {
    return true;
  }

  const values = data
    .map((item: Record<string, unknown>) => item[fieldName])
    .filter(Boolean);

  if (new Set(values).size === values.length) {
    return true;
  }

  /**
   * Prepare error messages for to be able recognized by formik
   */
  const entitiesMap = {};
  data.forEach((item, index) => {
    if (!entitiesMap[item[fieldName]]) {
      entitiesMap[item[fieldName]] = [];
    }
    entitiesMap[item[fieldName]].push(index);
  });

  let duplicatedIndexes: number[] = [];
  for (const key in entitiesMap) {
    if (entitiesMap[key].length > 1) {
      duplicatedIndexes = duplicatedIndexes.concat(entitiesMap[key]);
    }
  }

  uniqueFieldInArrayOfEntitiesKeyword.errors = [];

  for (const duplicatedIndex of duplicatedIndexes) {
    uniqueFieldInArrayOfEntitiesKeyword.errors.push({
      keyword: 'uniqueFieldInArrayOfEntities',
      message: `Values for field '${fieldName}' should not be repeated`,
      params: { fieldName },
      instancePath: `/${duplicatedIndex}/${fieldName}`,
    });
  }

  return false;
};
