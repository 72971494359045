import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import {
  useFetchClientsCombobox,
  useOvertimeBasisOptions,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateSitesRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { paymentTermsOptions } from 'src/config';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { omit } from 'lodash';
import { getTimezones } from 'src/modules/utils/dateWrapper';
import { updateSitesScheme } from 'src/modules/schemes/sites';
import { getClientsComboboxList } from 'src/modules/selectors/client';
import HoursOptionsComboBox from 'src/components/Formik/comboboxes-with-entities/HoursOptionsCombobox';
import WeekStartDayComboBox from 'src/components/Formik/comboboxes-with-entities/WeekStartDayCombobox';
import { getSiteTable } from 'src/modules/selectors/site';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import {
  baselineOptions,
  incentiveOptions,
  pricingOptions,
  statesOptions,
} from './CreateSite';

interface IUpdateSitesProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  sitesToUpdateIds: IdsArray;
}

export const UpdateSites = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  sitesToUpdateIds,
}: IUpdateSitesProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allSites = useSelector(getSiteTable);

  const sites = allSites
    .filter((site) => sitesToUpdateIds.includes(site.id))
    .map((item) => ({
      ...omit(
        item,
        'client',
        'recruitmentColumnsModifiedAt',
        'recruitmentColumnsModifiedByUserId',
      ),
      archived: Boolean(item.archived),
      lunchAdj: Boolean(item.lunchAdj),
      isDstApply: Boolean(item.isDstApply),
      financialAutomated: Boolean(item.financialAutomated),
    }));

  const validate = useValidate(updateSitesScheme);

  const formik = useFormik({
    initialValues: sites,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateSitesRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const fetchClientsCombobox = useFetchClientsCombobox();

  // make request to fetch clients and sites from the server if we don't have them in the store
  React.useEffect(() => {
    fetchClientsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch supervisors clients list from store
  const clients = useSelector(getClientsComboboxList);

  const overtimeBasisOptions = useOvertimeBasisOptions();

  // prepare timezones as options for combobox list autocomplete
  const timezones = getTimezones();

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('sites.update_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {sites.map((site, index) => (
              <DrawerBodySectionContent key={site.id}>
                <DrawerBodySectionTitle>{`${site.name}`}</DrawerBodySectionTitle>
                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm('name', index)}
                    label={t('sites.name')}
                    name={generateFullIdInMultiEntitiesForm('name', index)}
                    autoComplete="name"
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm('clientId', index)}
                    placeholder={t('sites.client')}
                    label={t('sites.client')}
                    options={clients as ComboBoxOption[]}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    required
                    variant="outlined"
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm('costPlus', index)}
                    name={generateFullIdInMultiEntitiesForm('costPlus', index)}
                    label={t('sites.costPlus')}
                    autoComplete="costPlus"
                    formik={formik}
                    type="number"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    required
                    variant="outlined"
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm(
                      'requiredAssociates',
                      index,
                    )}
                    name={generateFullIdInMultiEntitiesForm(
                      'requiredAssociates',
                      index,
                    )}
                    label={t('sites.requiredAssociates')}
                    autoComplete="requiredAssociates"
                    formik={formik}
                    type="number"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    required
                    variant="outlined"
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm(
                      'fcGmPercentage',
                      index,
                    )}
                    name={generateFullIdInMultiEntitiesForm(
                      'fcGmPercentage',
                      index,
                    )}
                    label={t('sites.fcGmPercentage')}
                    formik={formik}
                    type="number"
                    slotProps={{
                      input: {
                        min: 1,
                        max: 100,
                      },
                    }}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm(
                      'paymentTerms',
                      index,
                    )}
                    placeholder={t('sites.paymentTermsTitle')}
                    label={t('sites.paymentTermsTitle')}
                    options={paymentTermsOptions as ComboBoxOption[]}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm('absenteeism', index)}
                    name={generateFullIdInMultiEntitiesForm(
                      'absenteeism',
                      index,
                    )}
                    label={t('sites.absenteeism')}
                    formik={formik}
                    type="number"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    required
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm(
                      'managementCost',
                      index,
                    )}
                    name={generateFullIdInMultiEntitiesForm(
                      'managementCost',
                      index,
                    )}
                    label={t('sites.managementCost')}
                    autoComplete="managementCost"
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm('timezone', index)}
                    placeholder={t('sites.timezone')}
                    label={t('sites.timezone')}
                    options={timezones as any}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm('state', index)}
                    placeholder={t('sites.state')}
                    label={t('sites.state')}
                    options={statesOptions}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm('pricingType', index)}
                    placeholder={t('sites.pricingType')}
                    label={t('sites.pricingType')}
                    options={(pricingOptions as unknown) as ComboBoxOption[]}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={`${index}.incentiveType`}
                    label={t('sites.incentiveType')}
                    placeholder={t('sites.incentiveType')}
                    options={(incentiveOptions as unknown) as ComboBoxOption[]}
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <HoursOptionsComboBox
                    id={generateFullIdInMultiEntitiesForm('hoursOption', index)}
                    placeholder={t('sites.hoursOption')}
                    label={t('sites.hoursOption')}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <WeekStartDayComboBox
                    id={generateFullIdInMultiEntitiesForm(
                      'startWeekDay',
                      index,
                    )}
                    placeholder={t('sites.startWeekDay')}
                    label={t('sites.startWeekDay')}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm(
                      'baselineType',
                      index,
                    )}
                    placeholder={t('sites.baselineType')}
                    label={t('sites.baselineType')}
                    options={(baselineOptions as unknown) as ComboBoxOption[]}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm(
                      'overtimeBasis',
                      index,
                    )}
                    placeholder={t('sites.overtimeBasis')}
                    label={t('sites.overtimeBasis')}
                    options={
                      (overtimeBasisOptions as unknown) as ComboBoxOption[]
                    }
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    type="number"
                    variant="outlined"
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm(
                      'numPointsToReview',
                      index,
                    )}
                    name={generateFullIdInMultiEntitiesForm(
                      'numPointsToReview',
                      index,
                    )}
                    label={t('sites.numPointsToReview')}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCheckbox
                    required
                    id={generateFullIdInMultiEntitiesForm('lunchAdj', index)}
                    label={t('sites.lunchAdj')}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCheckbox
                    required
                    id={generateFullIdInMultiEntitiesForm('isDstApply', index)}
                    label={t('sites.isDstApply')}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCheckbox
                    required
                    id={generateFullIdInMultiEntitiesForm(
                      'financialAutomated',
                      index,
                    )}
                    label={t('sites.financialAutomated')}
                    formik={formik}
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
