import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IExcessiveHoursModel,
  ListWithSummary,
} from '../types';
import { ExportSagaCreator } from '../utils/helpers/creators/export';

/**
 * Get shift list
 */
export const getExcessiveHoursListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IExcessiveHoursModel> = yield call(
      Api.ExcessiveHours.list,
      action.payload,
    );
    yield put(actions.getExcessiveHoursListSuccess(response));
  } catch (e) {
    yield put(actions.getExcessiveHoursListFailed());
  }
};

/**
 * Get shift count
 */
export const getExcessiveHoursCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.ExcessiveHours.count,
      action?.payload,
    );
    yield put(actions.getExcessiveHoursCountSuccess(response));
  } catch (e) {
    yield put(actions.getExcessiveHoursCountFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.ExcessiveHours.export,
  actionFailed: actions.exportExcessiveHoursFailed,
  actionSuccess: actions.exportExcessiveHoursSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportExcessiveHoursRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getExcessiveHoursListRequest,
    saga: getExcessiveHoursListRequestSaga,
  },
  {
    action: actions.getExcessiveHoursCountRequest,
    saga: getExcessiveHoursCountRequestSaga,
  },
  {
    action: actions.exportExcessiveHoursRequest,
    saga: exportExcessiveHoursRequestSaga,
  },
];
