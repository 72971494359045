import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateDstScheduleRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IDstScheduleModel,
  PayloadWithNavigateFunc,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new DST Schedule
 */
export const createDstScheduleRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreateDstScheduleRequest>
>): SagaIterator {
  try {
    const response: IDstScheduleModel = yield call(
      Api.DstSchedule.create,
      data,
    );
    yield put(actions.flushDstScheduleState());
    yield put(actions.createDstScheduleSuccess(response));
    yield call(navigate, { pathname: '/dst-schedule' });
  } catch (e) {
    yield put(actions.createDstScheduleFailed());
  }
};

/**
 * Get DST Schedule list
 */
export const getDstScheduleListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IDstScheduleModel[] = yield call(
      Api.DstSchedule.list,
      action.payload,
    );
    yield put(actions.getDstScheduleListSuccess(response));
  } catch (e) {
    yield put(actions.getDstScheduleListFailed());
  }
};

/**
 * Get DST Schedule count
 */
export const getDstScheduleCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.DstSchedule.count,
      action?.payload,
    );
    yield put(actions.getDstScheduleCountSuccess(response));
  } catch (e) {
    yield put(actions.getDstScheduleCountFailed());
  }
};

/**
 * Delete DST Schedule
 */
export const deleteDstScheduleRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.DstSchedule.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushDstScheduleState());
  } catch (e) {
    yield put(actions.deleteDstScheduleFailed());
  }
};

/**
 * Bulk update DST Schedule
 */
export const updateDstSchedulesRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IDstScheduleModel[]>>): SagaIterator {
  try {
    yield call(Api.DstSchedule.bulkUpdate, data);
    yield put(actions.flushDstScheduleState());
    yield call(navigate, { pathname: '/dst-schedule' });
  } catch (e) {
    yield put(actions.updateDstSchedulesFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.DstSchedule.export,
  actionFailed: actions.exportDstScheduleFailed,
  actionSuccess: actions.exportDstScheduleSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportDstScheduleRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createDstScheduleRequest,
    saga: createDstScheduleRequestSaga,
  },
  {
    action: actions.getDstScheduleListRequest,
    saga: getDstScheduleListRequestSaga,
  },
  {
    action: actions.getDstScheduleCountRequest,
    saga: getDstScheduleCountRequestSaga,
  },
  {
    action: actions.deleteDstScheduleRequest,
    saga: deleteDstScheduleRequestSaga,
  },
  // bulk updating DST Schedule
  {
    action: actions.updateDstSchedulesRequest,
    saga: updateDstSchedulesRequestSaga,
  },
  {
    action: actions.exportDstScheduleRequest,
    saga: exportDstScheduleRequestSaga,
  },
];
