import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/joy';

import { MUI_SIZE_12, MUI_SIZE_6 } from 'src/config';
import {
  useTurnoverActiveData,
  useTurnoverAddedData,
  useTurnoverLostData,
  useTurnoverTerminatedData,
} from 'src/modules/utils/hooks/turnover.hooks';
import { TurnoverDashboardChart } from '../components/TurnoverDashboardChart';
import { TurnoverDashboardPeriod } from '../components/TurnoverDashboardFilterPanel';

interface ITurnoverDashboardPanelProps {
  siteId?: number;
  year?: number;
  week?: number;
  date?: string;
  period?: TurnoverDashboardPeriod;
  terminationReason?: string;
}

export const TurnoverDashboardPanel: React.FC<ITurnoverDashboardPanelProps> = ({
  siteId,
  date,
  year,
  week,
  period = TurnoverDashboardPeriod.last30Days,
  terminationReason,
}) => {
  const { t } = useTranslation();

  const {
    series: seriesAdded,
    categories: categoriesAdded,
    isDataLoading: isDataLoadingAdded,
  } = useTurnoverAddedData({
    siteId,
    date,
    year,
    week,
    period,
  });

  const {
    series: seriesActive,
    categories: categoriesActive,
    isDataLoading: isDataLoadingActive,
  } = useTurnoverActiveData({
    siteId,
    date,
    year,
    week,
    period,
  });

  const {
    series: seriesLost,
    categories: categoriesLost,
    isDataLoading: isDataLoadingLost,
  } = useTurnoverLostData({
    siteId,
    date,
    year,
    week,
    period,
  });

  const {
    series: seriesTerminated,
    categories: categoriesTerminated,
    isDataLoading: isDataLoadingTerminated,
  } = useTurnoverTerminatedData({
    siteId,
    date,
    year,
    week,
    period,
    terminationReason,
  });

  return (
    <Grid container spacing={2}>
      <Grid xs={MUI_SIZE_12} md={MUI_SIZE_6}>
        <TurnoverDashboardChart
          title={t('turnover.active_emp')}
          categories={categoriesActive ?? []}
          showNoData={!seriesActive.length}
          series={seriesActive ?? []}
          isLoading={isDataLoadingActive}
        />
      </Grid>
      <Grid xs={MUI_SIZE_12} md={MUI_SIZE_6}>
        <TurnoverDashboardChart
          title={t('turnover.count_added')}
          showNoData={!seriesAdded.length}
          categories={categoriesAdded ?? []}
          series={seriesAdded ?? []}
          isLoading={isDataLoadingAdded}
        />
      </Grid>
      <Grid xs={MUI_SIZE_12} md={MUI_SIZE_6}>
        <TurnoverDashboardChart
          title={t('turnover.lost_emp')}
          showNoData={!seriesLost.length}
          categories={categoriesLost ?? []}
          series={seriesLost ?? []}
          isLoading={isDataLoadingLost}
        />
      </Grid>
      <Grid xs={MUI_SIZE_12} md={MUI_SIZE_6}>
        <TurnoverDashboardChart
          title={t('turnover.terminated_emp')}
          showNoData={!seriesTerminated.length}
          categories={categoriesTerminated ?? []}
          series={seriesTerminated ?? []}
          isLoading={isDataLoadingTerminated}
        />
      </Grid>
    </Grid>
  );
};
