import React from 'react';
import { Drawer } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { ICreateDstScheduleRequest } from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { createDstScheduleScheme } from 'src/modules/schemes/dst-schedule/create-dst-schedule.scheme';
import { Api } from 'src/modules/utils';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import FormikDatepicker from '../../../../components/Formik/FormikDatepicker';

const initialValues = {
  date: '',
  isAddition: true,
  isApplied: false,
};

interface IDstScheduleCreateProps {
  isOpen: boolean;
  onCreate: () => void;
  onClose: () => void;
}

export const DstScheduleCreate: React.FC<IDstScheduleCreateProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const { t } = useTranslation();

  const validate = useValidate(createDstScheduleScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: ICreateDstScheduleRequest) => {
      await Api.DstSchedule.create(data);
      formik.resetForm();
      onCreate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('dst_schedule.create_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikDatepicker
              required
              fullWidth
              label={t('dst_schedule.date')}
              id="date"
              formik={formik as any}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="isAddition"
              formik={formik}
              label={`${t('dst_schedule.is_addition')} ${
                formik?.values?.isAddition ? 'DST End' : 'DST Start'
              }`}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
