import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import {
  useFetchClientsCombobox,
  useOvertimeBasisOptions,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { createSiteRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import {
  HoursOptions,
  IncentiveType,
  WeekStartDay,
  paymentTermsOptions,
} from 'src/config';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { getTimezones, guessTimezone } from 'src/modules/utils/dateWrapper';
import { OvertimeBasisOptions } from 'src/config/overtime-basis-options';
import { usStates } from 'src/config/us-states';
import { createSiteScheme } from 'src/modules/schemes/sites';
import { getClientsComboboxList } from 'src/modules/selectors/client';
import HoursOptionsComboBox from 'src/components/Formik/comboboxes-with-entities/HoursOptionsCombobox';
import WeekStartDayComboBox from 'src/components/Formik/comboboxes-with-entities/WeekStartDayCombobox';

const initialValues = {
  name: '',
  clientId: NaN,
  costPlus: 0,
  requiredAssociates: 0,
  timezone: guessTimezone(),
  state: '',
  lunchAdj: false,
  isDstApply: true,
  financialAutomated: false,
  managementCost: 0,
  fcGmPercentage: 20,
  pricingType: 'single' as const,
  incentiveType: 'byDepartment' as const,
  hoursOption: HoursOptions.nGROUP,
  startWeekDay: WeekStartDay.Sunday,
  baselineType: 'cpu_based' as const,
  overtimeBasis: OvertimeBasisOptions.weekly,
};

export const pricingOptions = [
  { id: 'single', name: 'Single' },
  { id: 'range', name: 'Range' },
];

export const incentiveOptions = [
  { id: IncentiveType.general, name: 'General' },
  { id: IncentiveType.byDepartment, name: 'By Department' },
];

export const baselineOptions = [
  { id: 'cpu_based', name: 'CPU Based' },
  { id: 'area_based', name: 'Area Based' },
];

export const statesOptions = Array.from(
  usStates,
  (state) =>
    (({
      id: state,
      name: state,
    } as unknown) as ComboBoxOption),
);

interface ICreateSiteProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateSite = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateSiteProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(createSiteScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createSiteRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const fetchClientsCombobox = useFetchClientsCombobox();

  const overtimeBasisOptions = useOvertimeBasisOptions();

  React.useEffect(() => {
    fetchClientsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch clients list from store
  const clients = useSelector(getClientsComboboxList);

  const timezones = getTimezones();

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('sites.create_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="name"
                label={t('sites.name')}
                name="name"
                autoComplete="name"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="clientId"
                placeholder={t('sites.client')}
                label={t('sites.client')}
                options={clients as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                required
                variant="outlined"
                fullWidth
                id="costPlus"
                label={t('sites.costPlus')}
                name="costPlus"
                autoComplete="costPlus"
                formik={formik}
                type="number"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                required
                variant="outlined"
                fullWidth
                id="requiredAssociates"
                label={t('sites.requiredAssociates')}
                name="requiredAssociates"
                autoComplete="requiredAssociates"
                formik={formik}
                type="number"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                required
                variant="outlined"
                fullWidth
                id="fcGmPercentage"
                label={t('sites.fcGmPercentage')}
                name="fcGmPercentage"
                formik={formik}
                type="number"
                slotProps={{
                  input: {
                    min: 1,
                    max: 100,
                  },
                }}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="paymentTerms"
                placeholder={t('sites.paymentTermsTitle')}
                label={t('sites.paymentTermsTitle')}
                options={paymentTermsOptions as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="absenteeism"
                label={t('sites.absenteeism')}
                name="absenteeism"
                formik={formik}
                type="number"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                id="managementCost"
                label={t('sites.managementCost')}
                name="managementCost"
                autoComplete="managementCost"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="timezone"
                placeholder={t('sites.timezone')}
                label={t('sites.timezone')}
                options={timezones as any}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="state"
                placeholder={t('sites.state')}
                label={t('sites.state')}
                options={statesOptions}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="pricingType"
                placeholder={t('sites.pricingType')}
                label={t('sites.pricingType')}
                options={(pricingOptions as unknown) as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="incentiveType"
                placeholder={t('sites.incentiveType')}
                label={t('sites.incentiveType')}
                options={(incentiveOptions as unknown) as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <HoursOptionsComboBox
                id="hoursOption"
                placeholder={t('sites.hoursOption')}
                label={t('sites.hoursOption')}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <WeekStartDayComboBox
                id="startWeekDay"
                placeholder={t('sites.startWeekDay')}
                label={t('sites.startWeekDay')}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="baselineType"
                placeholder={t('sites.baselineType')}
                label={t('sites.baselineType')}
                options={(baselineOptions as unknown) as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="overtimeBasis"
                placeholder={t('sites.overtimeBasis')}
                label={t('sites.overtimeBasis')}
                options={(overtimeBasisOptions as unknown) as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                type="number"
                variant="outlined"
                fullWidth
                id="numPointsToReview"
                label={t('sites.numPointsToReview')}
                name="numPointsToReview"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                required
                id="lunchAdj"
                label={t('sites.lunchAdj')}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                required
                id="isDstApply"
                label={t('sites.isDstApply')}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                required
                id="financialAutomated"
                label={t('sites.financialAutomated')}
                formik={formik}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
