import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { ExcessiveHoursReport } from './panels/ExcessiveHoursReport';

export const ExcessiveHours = () => {
  const { t } = useTranslation();

  const hasUserAccessToExcessiveHoursTab = useHasUserAccessToViewTab(
    tabsPaths.REPORTS_EXCESSIVE_HOURS_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('payboard.excessive_hours'),
          hasUserAccess: hasUserAccessToExcessiveHoursTab,
          component: <ExcessiveHoursReport />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [hasUserAccessToExcessiveHoursTab, t],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
