import React from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/joy';
import { map, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  IdsArray,
  IDstScheduleModel,
  IUpdateDstSchedule,
} from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Api } from 'src/modules/utils';
import { updateDstScheduleScheme } from 'src/modules/schemes/dst-schedule/update-dst-schedule.scheme';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { ifSiteHasLunch } from 'src/modules/selectors/site';
import FormikDatepicker from '../../../../components/Formik/FormikDatepicker';

interface IDstScheduleUpdateProps {
  isOpen: boolean;
  ids: IdsArray;
  onUpdate: () => void;
  onClose: () => void;
}

export const DstScheduleUpdate: React.FC<IDstScheduleUpdateProps> = ({
  isOpen,
  ids,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const hasLunch = useSelector(ifSiteHasLunch);

  const [initialValues, setInitialValues] = React.useState<
    Array<IDstScheduleModel>
  >([]);

  React.useEffect(() => {
    if (isOpen) {
      Api.DstSchedule.list({
        filter: { where: { id: { inq: ids } } },
      }).then((data) =>
        setInitialValues(
          data.map((i) => {
            return omit(i, ['punchesBackup', 'isApplied']) as IDstScheduleModel;
          }),
        ),
      );
    }
  }, [hasLunch, ids, isOpen]);

  const validate = useValidate(updateDstScheduleScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: IUpdateDstSchedule[]) => {
      await Api.DstSchedule.bulkUpdate(data);
      formik.resetForm();
      onUpdate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('dst_schedule.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        {map(formik.values, (incentivePrice, index) => (
          <DrawerBodyContent key={incentivePrice.id}>
            <FormFieldContainer>
              <FormikDatepicker
                required
                fullWidth
                label={t('dst_schedule.date')}
                id={`${index}.date`}
                formik={formik as any}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id={`${index}.isAddition`}
                formik={formik}
                label={`${t('dst_schedule.is_addition')} ${
                  formik?.values[index]?.isAddition ? 'DST End' : 'DST Start'
                }`}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
