import { createReducer } from 'redux-act';
import * as actions from '../actions';
import {
  ICountResponse,
  IExcessiveHoursModel,
  IExcessiveHoursState,
  ListWithSummary,
} from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IExcessiveHoursState = {
  refreshKey: 0,
  list: { items: [], summary: [] },
  count: 0,
};

/**
 * Flush actions
 */
const flushExcessiveHoursState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Get list actions
 */
const getExcessiveHoursListRequest = (state: IExcessiveHoursState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getExcessiveHoursListSuccess = (
  state: IExcessiveHoursState,
  payload: ListWithSummary<IExcessiveHoursModel>,
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getExcessiveHoursListFailed = (
  state: IExcessiveHoursState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getExcessiveHoursCountRequest = (state: IExcessiveHoursState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getExcessiveHoursCountSuccess = (
  state: IExcessiveHoursState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getExcessiveHoursCountFailed = (
  state: IExcessiveHoursState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IExcessiveHoursState>();

const exportExcessiveHoursRequest = exportRequest;
const exportExcessiveHoursSuccess = exportSuccess;
const exportExcessiveHoursFailed = exportFailed;

/**
 * Excessive Hours reducer
 */
export const excessiveHours = createReducer<IExcessiveHoursState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushExcessiveHoursState, flushExcessiveHoursState)
  // get list actions
  .on(actions.getExcessiveHoursListRequest, getExcessiveHoursListRequest)
  .on(actions.getExcessiveHoursListSuccess, getExcessiveHoursListSuccess)
  .on(actions.getExcessiveHoursListFailed, getExcessiveHoursListFailed)
  // get count actions
  .on(actions.getExcessiveHoursCountRequest, getExcessiveHoursCountRequest)
  .on(actions.getExcessiveHoursCountSuccess, getExcessiveHoursCountSuccess)
  .on(actions.getExcessiveHoursCountFailed, getExcessiveHoursCountFailed)
  // export actions
  .on(actions.exportExcessiveHoursRequest, exportExcessiveHoursRequest)
  .on(actions.exportExcessiveHoursSuccess, exportExcessiveHoursSuccess)
  .on(actions.exportExcessiveHoursFailed, exportExcessiveHoursFailed);

export default excessiveHours;
