import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  ISetArchiveStatusArgs,
  ISiteModel,
  ISiteState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { HoursOptions, WeekStartDay } from '../../../config';
import { OvertimeBasisOptions } from 'src/config/overtime-basis-options';

// default state
const defaultState: ISiteState = {
  refreshKey: 0,
  id: NaN,
  clientId: NaN,
  name: '',
  list: [],
  comboboxList: [],
  count: 0,
  client: {
    id: NaN,
    name: '',
    clientKey: '',
  },
  costPlus: 0,
  requiredAssociates: 0,
  timezone: 'UTC',
  lunchAdj: false,
  isDstApply: false,
  financialAutomated: false,
  managementCost: 0,
  fcGmPercentage: 20,
  globalDropdownList: [],
  globallySelected: [],
  isGloballySelectedSitesInitialized: false,
  pricingType: 'single',
  incentiveType: 'byDepartment',
  hoursOption: HoursOptions.nGROUP,
  startWeekDay: WeekStartDay.Sunday,
  baselineType: 'cpu_based' as const,
  overtimeBasis: OvertimeBasisOptions.weekly,
  isSitesDataLoading: false,
  isSiteDataProcessing: false,
  isSiteComboboxListInitialized: false,
};

/**
 * Flush actions
 */
const flushSiteState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createSiteRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  isSiteDataProcessing: true,
});

const createSiteSuccess = (state: ISiteState, payload: ISiteModel) => ({
  ...state,
  ...payload,
  error: false,
  isSiteDataProcessing: false,
});

const createSiteFailed = (state: ISiteState, payload: string) => ({
  ...state,
  error: payload,
  isSiteDataProcessing: false,
});

/**
 * Get list actions
 */
const getSiteListRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  isSitesDataLoading: true,
});

const getSiteListSuccess = (state: ISiteState, payload: ISiteModel[]) => ({
  ...state,
  list: payload,
  error: false,
  isSitesDataLoading: false,
});

const getSiteListFailed = (state: ISiteState, payload: string) => ({
  ...state,
  error: payload,
  isSitesDataLoading: false,
});

/**
 * Get count actions
 */
const getSiteCountRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  isSitesDataLoading: true,
});

const getSiteCountSuccess = (state: ISiteState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  isSitesDataLoading: false,
});

const getSiteCountFailed = (state: ISiteState, payload: string) => ({
  ...state,
  error: payload,
  isSitesDataLoading: false,
});

/**
 * Deletion actions
 */
const deleteSiteRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  isSiteDataProcessing: true,
});

const deleteSiteSuccess = (state: ISiteState) => ({
  ...state,
  isSiteDataProcessing: false,
});

const deleteSiteFailed = (state: ISiteState, payload: string) => ({
  ...state,
  error: payload,
  isSiteDataProcessing: false,
});

const setArchivedStatusOfSiteRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  isSiteDataProcessing: true,
});

const setArchivedStatusOfSiteSuccess = (
  state: ISiteState,
  payload: Array<ISetArchiveStatusArgs>,
) => {
  const idsToExclude = payload.map(({ id }) => id);

  return {
    ...state,
    list: state.list.filter((item) => !idsToExclude.includes(item.id)),
    isSiteDataProcessing: false,
  };
};

const setArchivedStatusOfSiteFailed = (state: ISiteState, payload: string) => ({
  ...state,
  error: payload,
  isSiteDataProcessing: false,
});

/**
 * Updating actions
 */
const updateSitesRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  isSiteDataProcessing: true,
});

const updateSitesFailed = (state: ISiteState, payload: string) => ({
  ...state,
  error: payload,
  isSiteDataProcessing: false,
});
const updateSitesSuccess = (state: ISiteState) => ({
  ...state,
  isSiteDataProcessing: false,
});

const updateSitesDesiredEmployeesNumberRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  isSiteDataProcessing: true,
});
const updateSitesDesiredEmployeesNumberSuccess = (state: ISiteState) => ({
  ...state,
  error: false,
  isSiteDataProcessing: false,
});

const updateSitesDesiredEmployeesNumberFailed = (state: ISiteState) => ({
  ...state,
  isSiteDataProcessing: false,
});

/**
 * Get combobox list actions
 */
const getSiteComboboxListRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getSiteComboboxListSuccess = (
  state: ISiteState,
  payload: Partial<ISiteModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
  isSiteComboboxListInitialized: true,
});

const getSiteComboboxListFailed = (state: ISiteState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isSiteComboboxListInitialized: true,
});
/**
 * Get for global dropdown actions
 */
const getSiteForGlobalDropdownRequest = (state: ISiteState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getSiteForGlobalDropdownSuccess = (
  state: ISiteState,
  {
    globalDropdownList,
    globallySelected,
  }: { globalDropdownList: ISiteModel[]; globallySelected: number[] },
) => ({
  ...state,
  globalDropdownList,
  globallySelected,
  error: false,
  isGloballySelectedSitesInitialized: true,
  is_data_requested: false,
});

const getSiteForGlobalDropdownFailed = (
  state: ISiteState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isGloballySelectedSitesInitialized: true,
});

const setGloballySelectedSites = (state: ISiteState, payload: number[]) => ({
  ...state,
  globallySelected: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<ISiteState>();

const exportSiteRequest = exportRequest;
const exportSiteSuccess = exportSuccess;
const exportSiteFailed = exportFailed;

/**
 * Site reducer
 */
export const site = createReducer<ISiteState>({}, defaultState)
  // flush actions
  .on(actions.flushSiteState, flushSiteState)
  // creation actions
  .on(actions.createSiteRequest, createSiteRequest)
  .on(actions.createSiteSuccess, createSiteSuccess)
  .on(actions.createSiteFailed, createSiteFailed)
  // get list actions
  .on(actions.getSiteListRequest, getSiteListRequest)
  .on(actions.getSiteListSuccess, getSiteListSuccess)
  .on(actions.getSiteListFailed, getSiteListFailed)
  // get count actions
  .on(actions.getSiteCountRequest, getSiteCountRequest)
  .on(actions.getSiteCountSuccess, getSiteCountSuccess)
  .on(actions.getSiteCountFailed, getSiteCountFailed)
  // deletion actions
  .on(actions.deleteSiteRequest, deleteSiteRequest)
  .on(actions.deleteSiteSuccess, deleteSiteSuccess)
  .on(actions.deleteSiteFailed, deleteSiteFailed)
  // archive actions
  .on(actions.setArchivedStatusOfSiteRequest, setArchivedStatusOfSiteRequest)
  .on(actions.setArchivedStatusOfSiteSuccess, setArchivedStatusOfSiteSuccess)
  .on(actions.setArchivedStatusOfSiteFailed, setArchivedStatusOfSiteFailed)
  // updating actions
  .on(actions.updateSitesRequest, updateSitesRequest)
  .on(actions.updateSitesSuccess, updateSitesSuccess)
  .on(actions.updateSitesFailed, updateSitesFailed)
  .on(
    actions.updateSitesDesiredEmployeesNumberRequest,
    updateSitesDesiredEmployeesNumberRequest,
  )
  .on(
    actions.updateSitesDesiredEmployeesNumberSuccess,
    updateSitesDesiredEmployeesNumberSuccess,
  )
  .on(
    actions.updateSitesDesiredEmployeesNumberFailed,
    updateSitesDesiredEmployeesNumberFailed,
  )
  // get combobox list actions
  .on(actions.getSiteComboboxListRequest, getSiteComboboxListRequest)
  .on(actions.getSiteComboboxListSuccess, getSiteComboboxListSuccess)
  .on(actions.getSiteComboboxListFailed, getSiteComboboxListFailed)
  // get for global dropdown list actions
  .on(actions.getSiteForGlobalDropdownRequest, getSiteForGlobalDropdownRequest)
  .on(actions.getSiteForGlobalDropdownSuccess, getSiteForGlobalDropdownSuccess)
  .on(actions.getSiteForGlobalDropdownFailed, getSiteForGlobalDropdownFailed)
  // set sites globally
  .on(actions.setGloballySelectedSites, setGloballySelectedSites)
  // export actions
  .on(actions.exportSiteRequest, exportSiteRequest)
  .on(actions.exportSiteSuccess, exportSiteSuccess)
  .on(actions.exportSiteFailed, exportSiteFailed);

export default site;
