import React from 'react';
import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import { FetchedData } from './common/reports';
import { AnyObject, IFilterWhere } from '../../types';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from '../dateWrapper';
import { METHODS_TO_SCOPES } from '../../../config';
import { useTranslation } from 'react-i18next';
import { IHeadCellWithOrderConfig } from '../../types/table';
import { useBaseReport } from './reports.hooks';

export const useParseChangelogData = () => {
  return React.useCallback((data: FetchedData<AnyObject>) => {
    const items = data.items.map((item) => ({
      ...item,
      payload: (
        <>
          <JsonView
            data={item.payload}
            shouldExpandNode={() => false}
            style={defaultStyles}
          />
        </>
      ),
      resource: `${item.url} - ${METHODS_TO_SCOPES[item.method.toLowerCase()]}`,
      createdAt: composeDate(
        item.createdAt,
        parse(DATETIME_TIMEZONE),
        format(DATETIME_FORMAT_TO_SHOW),
      ),
    }));

    return {
      items,
      summary: data.summary,
      count: data.count,
    };
  }, []);
};

export const useParseChangelogHistoryData = () =>
  React.useCallback(
    (data: AnyObject[]) =>
      data.map((item) => ({
        ...item,
        diff: (
          <>
            <JsonView
              data={item.diff}
              shouldExpandNode={() => false}
              style={defaultStyles}
            />
          </>
        ),
        timestamp: composeDate(
          item.timestamp,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        ),
      })),
    [],
  );

export const useHistoryEmployeeTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'eq' as const,
      },
    };
  }, []);
};

export const useHistoryEmployeeComposeWhere = (where: Partial<AnyObject>) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.employeeId
        ? {
            employeeId: {
              eq: where.employeeId,
            },
          }
        : {}),
    }),
    [where.employeeId],
  );
};

export const useHistoryEmployee = (
  reportUrl: string,
  where: IFilterWhere,
  ignoreInitialRequest?: boolean,
) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'timestamp',
        orderConfig: { orderBy: 'timestamp' },
        label: t('changelog.history.timestamp'),
      },
      {
        id: 'email',
        orderConfig: { orderBy: 'email' },
        label: t('changelog.history.userEmail'),
      },
      {
        id: 'diff',
        orderConfig: { orderBy: 'diff' },
        label: t('changelog.history.diff'),
      },
    ],
    [t],
  );

  return useBaseReport<AnyObject[]>({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'timestamp',
    ignoreInitialRequest,
  });
};
