import React from 'react';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { useTranslation } from 'react-i18next';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { AnyObject } from 'src/modules/types';

interface IDstScheduleFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(filters: AnyObject): void;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const DstScheduleFilter: React.FC<IDstScheduleFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <CheckboxFilter
          label={getLabel({
            filterName: 'isApplied',
            labelPrefix: t('dst-schedule.is_applied'),
          })}
          {...getFilterCommonPropsByFilterName('isApplied')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <CheckboxFilter
          label={getLabel({
            filterName: 'isAddition',
            labelPrefix: t('dst_schedule.is_addition'),
          })}
          {...getFilterCommonPropsByFilterName('isAddition')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
