import React from 'react';
import * as Yup from 'yup';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { addProcessStatus } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import ResourcesWithActionsComboBox from 'src/components/Formik/comboboxes-with-entities/ResourcesWithActionsComboBox';
import { SCOPES_TO_METHODS } from 'src/config';

const initialValues = {
  IPv4: '',
  IPv6: '',
  resource: '',
  method: '',
};

const formikFieldToOmit = 'toOmit';

interface ICreateIpWhitelistItemProps {
  isOpen: boolean;
  onClose: () => void;
  onRequestStart: () => void;
  onRequestEnd: () => void;
}

export const CreateIpWhitelistItem = ({
  isOpen,
  onClose,
  onRequestEnd,
  onRequestStart,
}: ICreateIpWhitelistItemProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const ipWhiteListValidationSchema = Yup.object().shape({
    IPv4: Yup.string()
      .required(t('error.validation.required'))
      .matches(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        t('ip_whitelist.validation.valid_ipv4'),
      ),

    IPv6: Yup.string().matches(
      /^([a-f0-9:]+:+)+[a-f0-9]+$/,
      t('ip_whitelist.validation.valid_ipv6'),
    ),

    [formikFieldToOmit]: Yup.string().required(t('error.validation.required')),
  });

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema: ipWhiteListValidationSchema,
    onSubmit: async (data) => {
      onRequestStart();

      try {
        await Api.IpWhitelist.create({
          IPv4: data.IPv4,
          IPv6: data.IPv6,
          resource: data.resource,
          method: SCOPES_TO_METHODS[data.method],
        });
        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
        onClose();
        onRequestEnd();
      } catch {
        onClose();
        onRequestEnd();
      }
      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('ip_whitelist.create_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="IPv4"
                name="IPv4"
                label="IPv4"
                formik={formik}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="IPv6"
                name="IPv6"
                label="IPv6"
                formik={formik}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <ResourcesWithActionsComboBox
                id={formikFieldToOmit}
                formik={formik}
                action="method"
                resource="resource"
                label={t('ip_whitelist.resource')}
                error={(formik.errors as any)[formikFieldToOmit]}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
