import { IStoreState } from '../types';
import { createSelector } from 'reselect';

export const getShiftRefreshKey = (state: IStoreState) =>
  state.excessiveHours.refreshKey;

export const getExcessiveHoursData = (state: IStoreState) =>
  state.excessiveHours;

/**
 * Get server error
 * @param excessiveHours - State excessiveHours
 */
export const getServerError = ({ excessiveHours }: IStoreState) =>
  excessiveHours.error;

/**
 * Get excessiveHours list
 * @param excessiveHours - State excessiveHours
 */
export const getExcessiveHoursList = ({ excessiveHours }: IStoreState) =>
  excessiveHours.list;

/**
 * Get shift count
 * @param shift - State shift
 */
export const getExcessiveHoursCount = ({ excessiveHours }: IStoreState) =>
  excessiveHours.count;

export const getIsExcessHoursDataLoading = createSelector(
  getExcessiveHoursData,
  (data) => data.is_data_requested,
);
