import React from 'react';
import {
  FilterPanelCombobox,
  IFilterPanelComboboxProps,
} from '../FilterPanelCombobox';
import { useSelector } from 'react-redux';
import { useFetchTerminationReasonsCombobox } from 'src/modules/utils';
import { getTerminationReasonsComboboxList } from 'src/modules/selectors/terminationReason';
import { isEmpty } from 'lodash';

type IFilterPanelTerminationReasonsComboboxProps = Omit<
  IFilterPanelComboboxProps,
  'options' | 'value'
> & {
  propertyAsId?: 'id' | 'name';
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
  value?: number | string | null;
  siteId?: number;
};

export const FilterPanelTerminationReasonsCombobox = ({
  filterId,
  value,
  siteId,
  propertyAsId = 'id',
  ...props
}: IFilterPanelTerminationReasonsComboboxProps) => {
  const fetchTerminationReasonsCombobox = useFetchTerminationReasonsCombobox();

  const terminationReasons = useSelector(getTerminationReasonsComboboxList);

  React.useEffect(() => {
    if (isEmpty(terminationReasons)) {
      fetchTerminationReasonsCombobox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const options = React.useMemo(() => {
    if (siteId) {
      return terminationReasons.filter(
        (termiationReason) => termiationReason.siteId === siteId,
      );
    }

    return terminationReasons.map((termiationReason) => ({
      id: termiationReason[propertyAsId] as number,
      name: `${termiationReason?.name} / ${
        (termiationReason as any).site?.name
      }`,
    }));
  }, [siteId, terminationReasons, propertyAsId]);

  const comboboxValue = options.find((site) => site.id === value);

  return (
    <FilterPanelCombobox
      value={comboboxValue}
      filterId={filterId}
      options={options}
      {...props}
    />
  );
};
