import { JSONSchemaType } from 'ajv';
import { ICreateDstScheduleRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createDstScheduleScheme: JSONSchemaType<ICreateDstScheduleRequest> = {
  type: 'object',
  required: ['date', 'isAddition'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    date: {
      type: 'string',
      format: 'date',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        format: i18n.t('error.validation.date'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    isAddition: {
      type: 'boolean',
      nullable: false,
      errorMessage: {
        type: i18n.t('error.validation.boolean'),
      },
    },
  },
};
