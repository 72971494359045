import { SagaIterator } from 'redux-saga';
import * as auth from './auth';
import * as user from './user';
import * as position from './position';
import * as shift from './shift';
import * as dstSchedule from './dst-schedule';
import * as excessiveHours from './excessiveHours.sagas';
import * as client from './client';
import * as costPlusWeekly from './customerReports/costPlusWeekly.sagas';
import * as site from './site';
import * as employee from './employee';
import * as department from './department';
import * as logtime from './logtime.sagas';
import * as staffingProvider from './staffingProvider';
import * as skill from './skill';
import * as role from './role';
import * as employeeSkill from './employee-skill.sagas';
import * as workdays from './workdays.sagas';
import * as supervisorView from './supervisorView';
import * as issueType from './issueType';
import * as issue from './issue';
import * as pointType from './pointType';
import * as payboard from './payboard.sagas';
import * as filledAssociates from './filled-associates.sagas';
import * as turnover from './turnover.sagas';
import * as point from './point';
import { takeLatest } from 'redux-saga/effects';
import * as metatag from './metatag.sagas';
import * as standard from './standard.sagas';
import * as pricing from './pricing.sagas';
import * as notification from './notification';
import * as terminationReason from './terminationReason';
import * as recruitingDashboardSummary from './recruitingDashboardSummary';
import * as recruitingDashboardDetails from './recruitingDashboardDetails';
import * as openPosition from './openPosition';
import * as applicant from './applicant';
import * as policy from './policy';
import * as bom from './bom.sagas';
import * as siteImportingSettings from './site-importing-settings.sagas';
import * as production from './production.sagas';
import * as deleteRelations from './deleteRelations';
import * as checklist from './checklist.sagas';
import * as productionDailyUphBySite from './productionUphReports/productionDailyUphBySite.sagas';
import * as productionDailyUphBySiteAndSku from './productionUphReports/productionDailyUphBySiteAndMetatag.sagas';
import * as productionDailyUphBySiteAndEmployee from './productionUphReports/productionDailyUphBySiteAndEmployee.sagas';
import * as productionDailyUphBySiteSkuAndEmployee from './productionUphReports/productionDailyUphBySiteSkuAndEmployee.sagas';
import * as productionWeeklyUphBySite from './productionUphReports/productionWeeklyUphBySite.sagas';
import * as productionWeeklyUphBySiteAndSku from './productionUphReports/productionWeeklyUphBySiteAndSku.sagas';
import * as productionWeeklyUphBySiteAndEmployee from './productionUphReports/productionWeeklyUphBySiteAndEmployee.sagas';
import * as productionWeeklyUphBySiteSkuAndEmployee from './productionUphReports/productionWeeklyUphBySiteSkuAndEmployee.sagas';
import { ISagaActionBind } from '../types';
import * as cardinalHealthDailyUphBySite from './cardinal-health/dailyUphBySite.sagas';
import * as cardinalHealthDailyUphBySiteAndSku from './cardinal-health/dailyUphBySiteAndSku.sagas';
import * as cardinalHealthDailyUphBySiteAndEmployee from './cardinal-health/dailyUphBySiteAndEmployee.sagas';
import * as cardinalHealthDailyUphBySiteSkuAndEmployee from './cardinal-health/dailyUphBySiteSkuAndEmployee.sagas';
import * as cardinalHealthDailyUphBySiteAndShift from './cardinal-health/dailyUphBySiteAndShift.sagas';
import * as cardinalHealthWeeklyUphBySite from './cardinal-health/weeklyUphBySite.sagas';
import * as cardinalHealthWeeklyUphBySiteAndShift from './cardinal-health/weeklyUphBySiteAndShift.sagas';
import * as cardinalHealthWeeklyUphBySiteAndEmployee from './cardinal-health/weeklyUphBySiteAndEmployee.sagas';
import * as cardinalHealthWeeklyUphBySiteSkuAndEmployee from './cardinal-health/weeklyUphBySiteSkuAndEmployee.sagas';
import * as cardinalHealthWeeklyUphBySiteAndSku from './cardinal-health/weeklyUphBySiteAndSku.sagas';
import * as cardinalHealthWeeklyCpu from './cardinal-health/weeklyCpu.sagas';
import * as cardinalHealthWeeklyCpuDetailed from './cardinal-health/weeklyCpuDetailed.sagas';
import * as cardinalHealthDailyReport from './cardinal-health/dailyReport.sagas';
import * as optoroDailyReport from './optoro/dailyReport.sagas';
import * as expeditors from './expeditors/dailyReport.sagas';
import * as questionCategory from './question-category';
import * as question from './question';
import * as questionEmployee from './question-employee';
import * as siteTaktExportingSettings from './site-takt-exporting-settings.sagas';
import * as costPlusSettings from './cost-plus-settings.sagas';
import * as siteBillingDetails from './site-billing-details.sagas';
import * as ngroupBillingDetails from './ngroup-billing-details.sagas';
import * as additionalFee from './additional-fee.sagas';
import * as invoice from './invoice';
import * as area from './Area';
import * as issueTracker from './issueTracker';
import * as otherCosts from './other-costs.sagas';
import * as pageSetting from './page-setting.sagas';
import * as resources from './resources.sagas';
import * as optiturnCredentials from './optiturn-credentials.sagas';
import { cpuBasedBaseline, areaBasedBaseline, baseline } from './baseline';

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
  // add `autobind` lists into this array
  const autobind: ISagaActionBind<any>[][] = [
    applicant.autobind,
    auth.autobind,
    bom.autobind,
    deleteRelations.autobind,
    department.autobind,
    client.autobind,
    costPlusWeekly.autobind,
    employee.autobind,
    employeeSkill.autobind,
    issue.autobind,
    issueType.autobind,
    logtime.autobind,
    metatag.autobind,
    notification.autobind,
    openPosition.autobind,
    payboard.autobind,
    filledAssociates.autobind,
    point.autobind,
    pointType.autobind,
    policy.autobind,
    position.autobind,
    pricing.autobind,
    production.autobind,
    productionDailyUphBySite.autobind,
    productionDailyUphBySiteAndEmployee.autobind,
    productionDailyUphBySiteAndSku.autobind,
    productionDailyUphBySiteSkuAndEmployee.autobind,
    productionWeeklyUphBySite.autobind,
    productionWeeklyUphBySiteAndSku.autobind,
    productionWeeklyUphBySiteAndEmployee.autobind,
    productionWeeklyUphBySiteSkuAndEmployee.autobind,
    recruitingDashboardSummary.autobind,
    recruitingDashboardDetails.autobind,
    shift.autobind,
    excessiveHours.autobind,
    site.autobind,
    skill.autobind,
    role.autobind,
    staffingProvider.autobind,
    standard.autobind,
    supervisorView.autobind,
    terminationReason.autobind,
    turnover.autobind,
    user.autobind,
    workdays.autobind,
    checklist.autobind,
    siteImportingSettings.autobind,
    cardinalHealthDailyUphBySite.autobind,
    cardinalHealthDailyUphBySiteAndSku.autobind,
    cardinalHealthDailyUphBySiteAndEmployee.autobind,
    cardinalHealthDailyUphBySiteSkuAndEmployee.autobind,
    cardinalHealthDailyUphBySiteAndShift.autobind,
    cardinalHealthWeeklyUphBySite.autobind,
    cardinalHealthWeeklyUphBySiteAndShift.autobind,
    cardinalHealthWeeklyUphBySiteAndEmployee.autobind,
    cardinalHealthWeeklyUphBySiteSkuAndEmployee.autobind,
    cardinalHealthWeeklyUphBySiteAndSku.autobind,
    cardinalHealthDailyReport.autobind,
    cardinalHealthWeeklyCpu.autobind,
    cardinalHealthWeeklyCpuDetailed.autobind,
    optoroDailyReport.autobind,
    expeditors.autobind,
    questionCategory.autobind,
    question.autobind,
    questionEmployee.autobind,
    siteTaktExportingSettings.autobind,
    costPlusSettings.autobind,
    siteBillingDetails.autobind,
    ngroupBillingDetails.autobind,
    additionalFee.autobind,
    invoice.autobind,
    area.autobind,
    issueTracker.autobind,
    cpuBasedBaseline.autobind,
    areaBasedBaseline.autobind,
    baseline.autobind,
    otherCosts.autobind,
    pageSetting.autobind,
    resources.autobind,
    optiturnCredentials.autobind,
    dstSchedule.autobind,
  ];

  for (const sagas of autobind) {
    for (const { action, saga, bindFunc } of sagas) {
      yield (bindFunc ?? takeLatest)(action, saga);
    }
  }
}
