import React, { useEffect, useMemo /**, useState **/ } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import {
  exportExcessiveHoursRequest,
  getExcessiveHoursListRequest,
  getExcessiveHoursCountRequest,
} from '../../../../../modules/actions';
import {
  getExcessiveHoursCount,
  getExcessiveHoursList,
  getIsExcessHoursDataLoading,
} from '../../../../../modules/selectors/excessiveHours.selector';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { map } from 'lodash';
import {
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useFilter,
  useCreateExportProps,
  useFetchSitesCombobox,
  usePayboardListDefaultFilter,
} from '../../../../../modules/utils/hooks';
import { getDepartmentOptionsByGloballySites } from '../../../../../modules/selectors/department';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { useTranslation } from 'react-i18next';
import { getSitesComboboxList } from '../../../../../modules/selectors/site';
import { getShiftOptionsByGloballySites } from '../../../../../modules/selectors/shift';
import { isTableHasDefaultOrder } from 'src/modules/utils/hooks/table';

export const ExcessiveHoursReport = () => {
  const { t } = useTranslation();

  const defaultFilter = usePayboardListDefaultFilter();

  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchSitesComboBox = useFetchSitesCombobox();

  // make request to fetch departments from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesComboBox();
    fetchDepartmentsCombobox();
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // fetch departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );
  // fetch employees shifts list from store
  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);
  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const list = useSelector(getExcessiveHoursList, shallowEqual);
  const count = useSelector(getExcessiveHoursCount, shallowEqual);
  const exportProps = useCreateExportProps(exportExcessiveHoursRequest);
  const isTableDataLoading = useSelector(getIsExcessHoursDataLoading);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'extEmployeeId',
      disablePadding: false,
      label: t('payboard.extEmployeeId'),
    },
    {
      id: 'firstName',
      disablePadding: false,
      label: t('payboard.first_name'),
    },
    { id: 'lastName', disablePadding: false, label: t('payboard.last_name') },
    {
      id: 'staffingProvider',
      disablePadding: false,
      label: 'Staffing Provider',
    },
    { id: 'shift', disablePadding: false, label: t('payboard.shift') },
    {
      id: 'department',
      disablePadding: false,
      label: t('payboard.department'),
    },
    { id: 'site', disablePadding: false, label: t('payboard.site') },
    { id: 'year', disablePadding: false, label: t('payboard.year') },
    { id: 'week', disablePadding: false, label: t('payboard.week') },
    { id: 'day', disablePadding: false, label: t('payboard.day') },
    {
      id: 'weekEndingDate',
      disablePadding: false,
      label: t('payboard.week_ending_date'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'yearWeek',
      disablePadding: false,
      label: t('productions_uph_reports.year_week'),
    },
    {
      id: 'sumTimeTotal',
      disablePadding: false,
      label: t('payboard.time_total'),
    },
    {
      id: 'regularHoursDec',
      disablePadding: false,
      label: t('payboard.reg_hrs'),
    },
    {
      id: 'overtimeHoursDec',
      disablePadding: false,
      label: t('payboard.ot_hrs'),
    },
    {
      id: 'shiftDuration',
      disablePadding: false,
      label: t('payboard.shift_duration'),
      orderByAnotherField: 'regularHoursDec',
    },
    {
      id: 'excessPercent',
      disablePadding: false,
      label: t('payboard.excess'),
      orderByAnotherField: 'sumTimeTotalDec',
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'site',
      label: t('payboard.site'),
      operator: 'eq',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(sites, (site) => ({
            id: site.name,
            name: site.name,
          })),
        [sites],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'extEmployeeId',
      label: t('payboard.extEmployeeId'),
      operator: 'like',
    },
    {
      name: 'shiftId',
      label: t('payboard.shift'),
      operator: 'eq',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(shifts, (shift) => ({
            id: shift.id,
            name: shift.name,
          })),
        [shifts],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'department',
      label: t('payboard.department'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(departments, (department) => ({
            id: department.name,
            name: department.label,
          })),
        [departments],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'year',
      label: t('payboard.year'),
      operator: 'like',
    },
    {
      name: 'week',
      label: t('payboard.week'),
      operator: 'eq',
    },
  ];

  // make request to fetch excessive hours when component is mounted
  useEffect(() => {
    dispatcher(getExcessiveHoursListRequest(filterList));
    // get total count
    dispatcher(getExcessiveHoursCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order: tableOrder, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    const order = isTableHasDefaultOrder(tableOrder)
      ? ['firstName ASC, lastName ASC, yearWeek DESC']
      : tableOrder;

    const filter = {
      limit: rowsPerPage,
      offset,
      where,
      order,
    };

    dispatcher(
      getExcessiveHoursListRequest({
        filter,
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getExcessiveHoursCountRequest({ filter: { where } }));
  };

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={list.items}
        summaryRows={list.summary}
        count={count}
        isTableDataLoading={isTableDataLoading}
        selectIndex="" // if we pass an empty string it will use loop index for the key
        tableName={t('payboard.excessive_hours')}
        headCells={headCells}
        filters={filters}
        disableSelection={true}
        defaultOrderBy="yearWeek"
        defaultOrder="desc"
        onSync={onSync}
        exportProps={exportProps}
        additionalWhereForExport={defaultFilter.where}
      />
    </>
  );
};

export default ExcessiveHoursReport;
