import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { DstSchedule } from './panels/DstSchedule';

export const DstSchedulePage = () => {
  const { t } = useTranslation();

  const hasUserAccessToDstScheduleTab = useHasUserAccessToViewTab(
    tabsPaths.DST_SCHEDULE_TAB,
  );

  const dstScheduleTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.dst_schedule'),
          hasUserAccess: hasUserAccessToDstScheduleTab,
          component: <DstSchedule />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [t, hasUserAccessToDstScheduleTab],
  );

  return <TabsNgroup config={dstScheduleTabsConfig} />;
};
