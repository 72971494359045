import React, { useMemo } from 'react';
import {
  AnyObject,
  ICountResponse,
  IFilterData,
  IFilterInclude,
  IFilterWhere,
  IDstScheduleModel,
} from '../../types';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDataFetcherWithData } from './common/reports';
import { IHeadCellWithOrderConfig } from '../../types/table';
import { useGenerateHeadCellsData } from './table';
import { useDynamicBaseReport } from './reports.hooks';
import { useHasUserAccessToAction } from '../../../config';
import { manageEntitiesConfig } from '../../../config/manageEntitiesConfig';
import { NoIcon, YesIcon } from '../../../components/Icons';

export const useDstSchedulePermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.dstSchedule.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.dstSchedule.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.dstSchedule.delete.id,
  );

  return {
    allowedToCreate,
    allowedToUpdate,
    allowedToDelete,
  };
};

export interface IDstScheduleFilterPanelFilters {
  date?: string;
  isApplied?: boolean;
  isAddition?: boolean;
}

export const useDstScheduleTableFiltersConfiguration = () =>
  React.useMemo(
    () => ({
      date: {
        value: '',
        property: 'date',
        operator: 'eq' as const,
      },
      isApplied: {
        value: '',
        property: 'isApplied',
        operator: 'eq' as const,
      },
      isAddition: {
        value: '',
        property: 'isAddition',
        operator: 'eq' as const,
      },
    }),
    [],
  );

export const useDstScheduleComposeWhere = (
  where: Partial<IDstScheduleModel>,
) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.date
        ? {
            date: {
              eq: where.date,
            },
          }
        : {}),
      ...(where.isAddition
        ? {
            isAddition: {
              eq: where.isAddition,
            },
          }
        : {}),
      ...(where.isApplied
        ? {
            isApplied: {
              eq: where.isApplied,
            },
          }
        : {}),
    }),
    [where.date, where.isAddition, where.isApplied],
  );
};

export const useDstScheduleFilter = (
  appliedFilters: IDstScheduleFilterPanelFilters,
) => {
  const _where = useDstScheduleComposeWhere(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      order: ['id desc'],
    }),
    [_where],
  );
};

export const useDstSchedule = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
) => {
  const { t } = useTranslation();

  const initialData: AnyObject[] = [];

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, initialData);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'id',
        orderConfig: { orderBy: 'id' },
        label: t('dst_schedule.id'),
      },
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('dst_schedule.date'),
      },
      {
        id: 'isAddition',
        orderConfig: { orderBy: 'isAddition' },
        label: t('dst_schedule.is_addition'),
      },
      {
        id: 'isApplied',
        orderConfig: { orderBy: 'isApplied' },
        label: t('dst_schedule.is_applied'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const report = useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    data: data as AnyObject[],
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(report.data, (item) => {
        return {
          ...item,
          isAddition: item.isAddition ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          isApplied: item.isApplied ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
        };
      }),
    [report.data],
  );

  return {
    ...report,
    data: computedList,
  };
};
