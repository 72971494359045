import { JSONSchemaType } from 'ajv';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { ICreateSiteRequest } from '../../types';
import {
  HoursOptions,
  IncentiveType,
  PaymentTerms,
  WeekStartDay,
} from '../../../config';
import { OvertimeBasisOptions } from 'src/config/overtime-basis-options';

export const createSiteScheme: JSONSchemaType<ICreateSiteRequest> = {
  type: 'object',
  required: [
    'name',
    'clientId',
    'timezone',
    'startWeekDay',
    'requiredAssociates',
    'managementCost',
    'baselineType',
    'overtimeBasis',
    'fcGmPercentage',
  ],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    timezone: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    clientId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    costPlus: {
      default: 0,
      type: 'number',
      minimum: 0,
      maximum: 100,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    requiredAssociates: {
      default: 0,
      type: 'integer',
      minimum: 0,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    fcGmPercentage: {
      default: 0,
      isNotEmpty: true,
      type: 'number',
      minimum: 1,
      maximum: 100,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
        minimum: i18n.t('error.validation.exclusiveMinimum', {
          number: '1%',
        }),
        maximum: i18n.t('error.validation.maximum', {
          number: '100%',
        }),
      },
    },
    paymentTerms: {
      type: 'string',
      nullable: true,
      enum: Object.values(PaymentTerms),
    },
    absenteeism: {
      type: 'integer',
      nullable: true,
      minimum: 0,
      maximum: 100,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    lunchAdj: {
      default: false,
      type: 'boolean',
    },
    isDstApply: {
      default: false,
      type: 'boolean',
    },
    financialAutomated: {
      default: false,
      type: 'boolean',
    },
    archived: {
      default: false,
      nullable: true,
      type: 'boolean',
    },
    managementCost: {
      default: 0,
      type: ['string', 'number'] as any,
      pattern: '^$|^[+-]?([0-9]+\\.?[0-9]*|\\.[0-9]+)$',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
        pattern: i18n.t('error.validation.float'),
      },
    },
    pricingType: {
      default: 'single',
      isNotEmpty: true,
      enum: ['single', 'range'],
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    incentiveType: {
      default: IncentiveType.byDepartment,
      isNotEmpty: true,
      enum: Object.values(IncentiveType),
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    hoursOption: {
      default: HoursOptions.nGROUP,
      isNotEmpty: true,
      enum: Object.values(HoursOptions),
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    startWeekDay: {
      default: WeekStartDay.Sunday,
      isNotEmpty: true,
      type: 'number',
      minimum: 0,
      maximum: 7,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    baselineType: {
      default: 'cpu_based',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    overtimeBasis: {
      default: OvertimeBasisOptions.weekly,
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
