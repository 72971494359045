import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateDstScheduleRequest,
  IdsArray,
  IFilter,
  IDstScheduleModel,
  PayloadWithNavigateFunc,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushDstScheduleState = createAction(
  'Flush Dst Schedule data in store',
);

// Creation actions
export const createDstScheduleRequest = createAction<
  PayloadWithNavigateFunc<ICreateDstScheduleRequest>
>('Make request to create a new Dst Schedule');
export const createDstScheduleSuccess = createAction<IDstScheduleModel>(
  'New Dst Schedule has been created successfully',
);
export const createDstScheduleFailed = createAction(
  'Cannot create a new Dst Schedule',
);

// Fetch Dst Schedules actions
export const getDstScheduleListRequest = createAction<IFilter>(
  'Make request to fetch Dst Schedule list',
);
export const getDstScheduleListSuccess = createAction<IDstScheduleModel[]>(
  'DstSchedules list has been fetched successfully',
);
export const getDstScheduleListFailed = createAction(
  'Cannot fetch Dst Schedule list',
);

// get Dst Schedules count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getDstScheduleCountRequest = createAction<IFilter>(
  'Make request to get Dst Schedule count',
);
export const getDstScheduleCountSuccess = createAction<ICountResponse>(
  'DstSchedules count has been fetched successfully',
);
export const getDstScheduleCountFailed = createAction(
  'Cannot get Dst Schedule count',
);

// delete actions
export const deleteDstScheduleRequest = createAction<IdsArray>(
  'Make request to delete Dst Schedules',
);
export const deleteDstScheduleFailed = createAction(
  'Cannot delete Dst Schedules',
);

// Updating actions
export const updateDstSchedulesRequest = createAction<
  PayloadWithNavigateFunc<IDstScheduleModel[]>
>('Make request to update Dst Schedules');
export const updateDstSchedulesFailed = createAction(
  'Cannot update Dst Schedules',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('DstSchedule').takeExportActions();

export const exportDstScheduleRequest = exportReportRequest;
export const exportDstScheduleSuccess = exportReportSuccess;
export const exportDstScheduleFailed = exportReportFailed;
