import { createAction } from 'redux-act';
import {
  ICountResponse,
  IFilter,
  IExcessiveHoursModel,
  ListWithSummary,
} from '../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushExcessiveHoursState = createAction(
  'Flush Excessive Hours data in store',
);

// Fetch shifts actions
export const getExcessiveHoursListRequest = createAction<IFilter>(
  'Make request to fetch excessive hours list',
);
export const getExcessiveHoursListSuccess = createAction<
  ListWithSummary<IExcessiveHoursModel>
>('Excessive hours list has been fetched successfully');
export const getExcessiveHoursListFailed = createAction(
  'Cannot fetch excessive hours list',
);

// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getExcessiveHoursCountRequest = createAction<IFilter>(
  'Make request to get shift count',
);
export const getExcessiveHoursCountSuccess = createAction<ICountResponse>(
  'Excessive Hours count has been fetched successfully',
);
export const getExcessiveHoursCountFailed = createAction(
  'Cannot get excessive count',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('ExcessiveHours').takeExportActions();

export const exportExcessiveHoursRequest = exportReportRequest;
export const exportExcessiveHoursSuccess = exportReportSuccess;
export const exportExcessiveHoursFailed = exportReportFailed;
