import { IUpdateDstSchedule } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';
import { createDstScheduleScheme } from './create-dst-schedule.scheme';

export const updateDstScheduleScheme: PartialSchema<IUpdateDstSchedule[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createDstScheduleScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createDstScheduleScheme.properties,
    },
  },
};
